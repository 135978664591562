// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--oyEUz";
export var box__i7VO = "PlasmicFooter-module--box__i7VO--2y9qk";
export var box__h2Pj4 = "PlasmicFooter-module--box__h2Pj4--6k9pT";
export var box__a6E29 = "PlasmicFooter-module--box__a6E29--1rGtS";
export var iconLink__ltgk0 = "PlasmicFooter-module--iconLink__ltgk0--3qWWT";
export var svg__hlWad = "PlasmicFooter-module--svg__hlWad--30B01";
export var box__rQwzX = "PlasmicFooter-module--box__rQwzX--29ig9";
export var link__gGefU = "PlasmicFooter-module--link__gGefU--JEEhS";
export var box__pg4Z1 = "PlasmicFooter-module--box__pg4Z1--1coRV";
export var link__tZfSb = "PlasmicFooter-module--link__tZfSb--1KarW";
export var box__uJPsL = "PlasmicFooter-module--box__uJPsL--2VIoD";
export var box__oaS = "PlasmicFooter-module--box__oaS--37dr6";
export var box__xe824 = "PlasmicFooter-module--box__xe824--1SSvq";
export var link__xls6K = "PlasmicFooter-module--link__xls6K--164iC";
export var link__vvh0Y = "PlasmicFooter-module--link__vvh0Y--1Q9K3";
export var link__pvOga = "PlasmicFooter-module--link__pvOga--1WOR8";
export var link___2Pc0Y = "PlasmicFooter-module--link___2Pc0Y--3xJOn";
export var link__qpuMm = "PlasmicFooter-module--link__qpuMm--3wHg1";
export var box__rOwgm = "PlasmicFooter-module--box__rOwgm--2Fi3o";
export var box__otLvu = "PlasmicFooter-module--box__otLvu--1yhBF";
export var link__xs8A3 = "PlasmicFooter-module--link__xs8A3--2D5--";
export var link__amovs = "PlasmicFooter-module--link__amovs--3GiAF";
export var link__d2Bfh = "PlasmicFooter-module--link__d2Bfh--32ZCz";
export var link___4UVeb = "PlasmicFooter-module--link___4UVeb--ZL6Tu";
export var link__w0HF = "PlasmicFooter-module--link__w0HF--B_fFY";
export var box__dTaKs = "PlasmicFooter-module--box__dTaKs--3u5F0";
export var box__aY3Jz = "PlasmicFooter-module--box__aY3Jz--2Aqtc";
export var link__gCgcm = "PlasmicFooter-module--link__gCgcm--3S6it";
export var link__wu9My = "PlasmicFooter-module--link__wu9My--227XL";
export var link__e1Q86 = "PlasmicFooter-module--link__e1Q86--YB_JV";
export var link__eueMq = "PlasmicFooter-module--link__eueMq--6DE_X";
export var link__c1LBg = "PlasmicFooter-module--link__c1LBg--1kZWl";
export var box__oufTk = "PlasmicFooter-module--box__oufTk--oujJL";
export var box___64Tj = "PlasmicFooter-module--box___64Tj--2_IZy";
export var box__mmxiX = "PlasmicFooter-module--box__mmxiX--Jrh8y";
export var box___0MnsI = "PlasmicFooter-module--box___0MnsI--3SF5l";
export var textbox = "PlasmicFooter-module--textbox--1O3a3";
export var button = "PlasmicFooter-module--button--27DKR";
export var box__u6Igs = "PlasmicFooter-module--box__u6Igs--1pkqY";
export var box___1Mvyn = "PlasmicFooter-module--box___1Mvyn--2h1B9";
export var box__xhij8 = "PlasmicFooter-module--box__xhij8--1vCMD";
export var link___4QiiY = "PlasmicFooter-module--link___4QiiY--2pSuG";
export var link__oaf30 = "PlasmicFooter-module--link__oaf30--zzDPF";
export var box___9RuPl = "PlasmicFooter-module--box___9RuPl--1MuyE";
export var box__r47R2 = "PlasmicFooter-module--box__r47R2--2qgia";
export var iconLink__puyG7 = "PlasmicFooter-module--iconLink__puyG7--kohcp";
export var svg__tJNqg = "PlasmicFooter-module--svg__tJNqg--2TW1i";
export var iconLink___0Qwg = "PlasmicFooter-module--iconLink___0Qwg--EKaEv";
export var svg__yKXo3 = "PlasmicFooter-module--svg__yKXo3--3wCMY";
export var iconLink__bkpAy = "PlasmicFooter-module--iconLink__bkpAy--3FeKz";
export var svg__bK98N = "PlasmicFooter-module--svg__bK98N--3mxs6";